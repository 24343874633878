<template>
  <div>
    <section id="topo" class="seccao header-fixo">
      <Header
        :title="'Resumo da minha viagem'"
        :breadcrums="{ final_place: 'O meu carrinho' }"
        :cartItems="cartItems"
      />
      <section class="search-main" style="min-height: 60vh">
        <div id="itemContainer">
          <CartCard
            v-for="(item, i) in filtered_products"
            :key="'product_' + item.id"
            :product_data="item"
            :i="i"
            @update-cart="updateCart"
          />
        </div>
        <div v-if="emptyCart">
          <h3 class="empty-cart">
            Ainda não adicionou nenhum produto ao seu carrinho.
          </h3>
        </div>
        <div class="cart-final">
          <div class="text-container">
            <template v-if="!emptyCart">
              <p>Disponíveis soluções de financiamento: 24, 36 e 48 meses.</p>
              <h2 class="title">Desde 254,30€ / mês</h2>
              <div>
                <input
                  type="checkbox"
                  v-model="financiamento"
                  name="financiamento"
                  id="financiamento"
                  class="custom-checkbox checkbox-financiamento"
                />
                <label for="financiamento"
                  >Quero saber mais sobre as soluções de financiamento</label
                >
              </div>
              <!-- <a id="financiamento" href="" class="button ghost diagonal orange">
                            Saber mais
                        </a> -->
            </template>
          </div>

          <div class="resumo">
            <h3
              class="title"
              :style="{ color: '#444', marginTop: '0px', marginBottom: '15px' }"
            >
              Total sem IVA
              <span style="color: #ea8822"
                >{{ totalCost.replace(".", ",") }}€</span
              >
            </h3>
            <h3
              class="title"
              :style="{ color: '#444', marginTop: '0px', marginBottom: '15px' }"
            >
              IVA
              <span style="color: #ea8822">{{ iva.replace(".", ",") }}€</span>
            </h3>
            <h3
              class="title"
              :style="{ color: '#444', marginTop: '0px', marginBottom: '15px' }"
            >
              Total com IVA
              <span style="color: #ea8822; font-size: 4.3vh"
                >{{ finalCost.replace(".", ",") }}€</span
              >
            </h3>
          </div>
        </div>
        <div v-if="!emptyCart" class="cart-form">
          <p>Informações de Contacto</p>
          <form>
            <input
              type="text"
              v-model="nome"
              name="nome"
              id="nome"
              placeholder="Nome*"
            />
            <input
              type="text"
              v-model="pharma_name"
              name="pharma_name"
              id="pharma_name"
              placeholder="Nome Farmácia*"
            />
            <input
              type="text"
              v-model="telefone"
              name="telefone"
              id="telefone"
              placeholder="Telefone*"
            />
            <input
              type="text"
              v-model="email"
              name="email"
              id="email"
              placeholder="Email*"
            />
          </form>
          <div id="form-msg" v-if="required">
            Todos os campos são obrigatórios
          </div>
        </div>
        <div class="cart-footer">
          <template v-if="emptyCart">
            <h2 class="title-with-strap" style="width: 60vw">
              Preparado para iniciar a sua viagem?
            </h2>
            <div class="submit-cart small">
              <router-link to="/home" class="links-submit">
                Avançar
                <i class="fas fa-arrow-right"></i>
              </router-link>
            </div>
          </template>
          <template v-else>
            <h2 class="title-with-strap" style="width: 60vw">
              Preparado para avançar para a Nova Era?
            </h2>
            <div class="submit-cart large" @click="validateForm">
              <a class="links-submit">
                Avançar compra
                <i class="fas fa-arrow-right"></i>
              </a>
            </div>
          </template>
        </div>
      </section>
      <Footer />
    </section>

    <CartThanks v-if="confirmationLayer" :cart="cartBody" />
  </div>
</template>

<script type="text/javascript" src="@/assets/ficheiros/jquery-3.4.1.min.js"></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import Area from '@/components/Area.vue'
import CartCard from "@/components/CartCard.vue";
import CartThanks from "@/components/cartThanks.vue";

export default {
  name: "cart",
  components: {
    Header,
    Footer,
    //   Area,
    CartCard,
    CartThanks,
  },
  props: ["backdoor"],
  data() {
    return {
      breadcrums: {},
      loadingStyle: "flex",
      feed_products: [],
      financiamento: false,
      nome: "",
      telefone: "",
      email: "",
      pharma_name: "",
      required: false,
      query: null,
      n: 0,

      confirmationLayer: false,
      cartBody: {},
    };
  },
  mounted() {
    if (typeof this.$route.query.q != "undefined") {
      this.query = this.$route.query.q;
    }
  },
  created: async function () {
    await this.collect_feed_data();
  },
  watch: {
    filtered_products(val) {
      if (val) {
        // this.backdoor++
      }
    },
    "$route.query"(val) {
      this.query = this.$route.query.q;
    },
  },
  computed: {
    filtered_products() {
      this.m;
      let feed = this.materiaisCarrinho;
      feed = this.materiaisCarrinho.filter((e) => {
        if (this.query != null && this.query != "") {
          if (e.name.toLowerCase().includes(this.query.toLowerCase())) {
            return true;
          } else {
            let tagValidate;
            for (let tag of e.tags) {
              tag = tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
              let q_s = this.query.split(" ");
              for (let i in q_s) {
                if (q_s[i].length > 2) {
                  q_s[i] = q_s[i]
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "");
                  if (tag.toLowerCase().includes(q_s[i])) {
                    return true;
                  }
                }
              }
            }

            return false;
          }
        } else {
          return true;
        }
      });

      return feed;
    },

    materiaisCarrinho() {
      this.n;
      let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

      return temp;
    },
    m() {
      this.backdoor;
      return this.n;
    },
    cartItems() {
      this.m;
      let len = this.materiaisCarrinho.length || 0;

      return len;
    },
    totalCost() {
      this.n;
      var sum = 0;
      this.materiaisCarrinho.forEach((e) => {
        sum += e.price * e.quantity;
      });

      return parseFloat(sum).toFixed(2);
    },
    qt() {
      var qt = 0;
      this.materiaisCarrinho.forEach((e) => {
        qt += e.quantity;
      });
      return qt;
    },
    iva() {
      let temp = this.totalCost * 0.23;

      return parseFloat(temp).toFixed(2);
    },
    finalCost() {
      let temp = parseFloat(this.totalCost) + parseFloat(this.iva);

      return parseFloat(temp).toFixed(2);
    },
    emptyCart() {
      return this.filtered_products.length == 0;
    },
  },
  methods: {
    createCode(sufix) {
      let anf = window.sessionStorage.getItem("anf");
      let anf_final = anf.padStart(5, "0");
      let date = Date.now();
      let code = `${anf_final}/${date}`;
      return code + "/" + sufix;
    },
    validateForm() {
      const form = [this.nome, this.telefone, this.email, this.pharma_name];

      for (let i of form) {
        if (i == "" || i == null) {
          this.required = true;
          return;
        }
      }

      this.required = false;
      this.submitCart();
    },
    async submitCart() {
      this.n;
      let temp = {};

      temp.nome = this.nome;
      temp.email = this.email;
      temp.telefone = this.telefone;
      temp.pharma_name = this.pharma_name;
      temp.numero_encomenda = this.createCode("E");
      temp.numero_pedido = this.createCode("P");

      temp.baseCost = this.totalCost;
      temp.ivaCost = this.iva;
      temp.totalCost = this.finalCost;

      temp.financiamento = this.financiamento ? "Sim" : "Não";

      temp.items = this.materiaisCarrinho;

      let countConsumiveis = 0;

      for (let item of temp.items) {
        if (
          item.category_name == "Consumíveis " ||
          item.category_name == "Consumíveis"
        ) {
          countConsumiveis += 1;
        }
      }

      if (countConsumiveis == this.cartItems) {
        temp.template = "consumiveis";
      } else if (countConsumiveis > 0) {
        temp.template = "misto";
        // temp.template = "personalizada"
      } else {
        temp.template = "personalizada";
      }

      temp.referencias = this.cartItems;
      temp.artigos = this.qt;

      // temp.username = window.sessionStorage.getItem('name')
      temp.anf = window.sessionStorage.getItem("anf");
      // temp.email = window.sessionStorage.getItem('email')

      this.cartBody = temp;

      let subject = "Novo pedido de contacto";
      let to = "sebastiao@byd.pt";
      var url = "https://bydservices.pt/glintt-mailer/index.php";

      const body = {
        int_cart_mail: 1,
        cart: temp,
      };

      console.log("A enviar a mensagem interna...");

      var res = await this.call_api_post(url, body);

      if (res.resultado == "OK") {
        let subject = "Novo pedido de contacto";
        let to = "sebastiao@byd.pt";
        var url = "https://bydservices.pt/glintt-mailer/index.php";

        const body_ext = {
          ext_cart_mail: 1,
          cart: temp,
        };

        console.log("A enviar mensagem do cliente...");

        var res = await this.call_api_post(url, body_ext);

        if (res.resultado == "OK") {
          window.sessionStorage.removeItem("cart");
          this.updateCart();

          this.confirmationLayer = true;
          return;
        } else {
          alert(
            "Alguma coisa correu mal... Por favor entre em contacto por um dos contactos abaixo"
          );
        }
      } else {
        alert(
          "Alguma coisa correu mal... Por favor entre em contacto por um dos contactos abaixo"
        );
      }
    },
    async updateCart() {
      this.n += 1;
      this.$emit("update-cart");
    },
    collect_feed_data: async function () {
      this.loadingStyle = "flex";

      for (let i in this.materiaisCarrinho) {
        this.feed_products.push(this.materiaisCarrinho[i]);
      }

      // document.querySelector(".loadingLayer").style.display = "none"

      // operation = "areas/listAll"
      // headers = {}
      // url = 'https://bydservices.pt/glintt-api/public/api/' + operation

      // const families = await this.call_api_get(url, headers)

      // this.feed_families = families.families

      // $( document ).ready(function() {
      //     $("div.holder").jPages({
      //         containerID : "itemContainer",
      //         perPage: 3,
      //     });
      // });

      let vm = this;
      setTimeout(function () {
        vm.loadingStyle = "none";
      }, 500);

      return true;
    },

    call_api_get: async function (url, headers) {
      try {
        var response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        var result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        return "Error: " + error;
      }
    },
    call_api_post: async function (url, body) {
      try {
        var response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body, null, 2),
        });
        var result = await response.json();
        return result;
      } catch (error) {
        console.error("Error:", error);
        return "Error: " + error;
      }
    },
  },
};
</script>

<style src='@/assets/style.css'></style>
<style src='@/assets/produtos.css'></style>
<style src='@/assets/responsive.css'></style>
<style src='@/assets/ficheiros/swiper.min.css'></style>
<style src='@/assets/ficheiros/font-awesome.min.css'></style>
<style src='@/assets/ficheiros/jPages.css'></style>


<style scoped>
.empty-cart {
  text-align: center;
  font-size: 3.2vh !important;
  color: #666;
  font-weight: 500;
}
.submit-cart {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 50px;
  /* min-width: 15vw; */
  transform: skew(-15deg);
  justify-content: end;
  padding-left: 20px;
  margin-left: 7vw;
  border: 2px solid #ea8822;
  background-color: #ea8822;
  color: #fff;
}
.submit-cart.small {
  min-width: 9vw;
  position: absolute;
  right: 6.5%;
}
.submit-cart.large {
  min-width: 15vw;
}
.submit-cart:hover {
  cursor: pointer;
  background-color: #b35c00;
  border: 2px solid #b35c00;
  color: #eee;
}
.links-submit {
  transform: skew(15deg);
  font-size: 3.5vh;
  font-weight: lighter;
  text-decoration: none;
  color: inherit;
}
.links-submit i {
  align-self: flex-end;
  margin-left: 7px;
  z-index: 2;
}
.cart-footer {
  display: inline-flex;
  width: 100%;
  height: 175px;
  margin-top: 60px;
  background-color: #eee;
  margin-left: 30px;
  align-items: center;
  padding-right: 40px;
}

.cart-footer::before {
  content: "";
  height: 100%;
  top: 0;
  margin-left: -30px;
  width: 60px;
  background-color: #eee;
  transform: skew(-15deg);
  -webkit-transform: skew(-15deg);
  display: inline-block;
  z-index: -1;
}

#financiamento ~ label,
#financiamento ~ label:after {
  font-size: 20px;
}

.button.diagonal.ghost.orange {
  color: #ea8822;
}

.button.diagonal.ghost.orange::before {
  border: 1px solid #ea8822;
}

.resumo {
  /* position: relative; */
  z-index: 3;
  display: inline-block;
  width: 45%;
  margin-left: 3%;
  vertical-align: middle;
  text-align: right;
}

.text-container {
  /* position: relative; */
  z-index: 3;
  display: inline-block;
  width: 50%;
  margin-left: 3%;
  vertical-align: middle;
}
.text-container p {
  color: #000;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 0;
}
.text-container h2 {
  color: #ea8822;
  margin-top: 5px;
}
.cart-final {
  display: inline-flex;
  width: 100%;
  height: 175px;
  margin-top: 60px;
}
.search-header {
  /* width: 90%;
        padding: 30px 5%;
        margin-top: 120px; */
  width: auto;
  padding: 30px 100px;
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.s-header-left {
  display: inline-block;
}
.s-input-container {
  position: relative;
}
.s-input-container input::placeholder {
  font-size: 17px;
}
.searchInput {
  border-bottom-color: #ea8822;
  padding: 8px 35px;
  width: 210px;
}
.fa-search {
  color: #ea8822;
  display: inline-block;
  position: absolute;
  font-size: 22px;
  bottom: 9px;
}
.s-header-right {
  width: auto;
  display: inline-block;
  text-align: right;
}
.select-container {
  display: inline-block;
  width: 198px;
  height: 40px;
  transform: skew(-10deg);
  border: 1.5px solid #b7b9bb;
  margin-left: -1.5px;
  padding: 0 15px;
  border: 2px solid #999 !important;
}
.select-container select {
  transform: skew(10deg);
  appearance: none;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  position: relative;
  display: inline-block;
  color: #b7b9bb;
  font-size: 13px;
  color: #999 !important;
}
.select-container i {
  position: absolute;
  font-size: 17px;
  transform: skew(10deg);
  top: 12px;
  right: 20px;
  color: #b7b9bb;
}
.search-main {
  padding: 150px 100px;
}
.swiper-pagination-bullet-active,
.swiper-pagination-bullet {
  display: none;
}
.swipe-menu {
  position: relative;
  width: 100%;
  height: auto !important;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.links {
  color: inherit;
  text-decoration: none;
}
.video-container {
  display: flex;
  background: #000;
}
/* .video-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #000 !important;
        background-size: cover;
    } */
.prod-video {
  max-height: 100%;
  height: 100%;
  align-self: center;
}
.slider-swiper-button-next {
  background-image: url("/assets/imagens/arrow_next_gray.svg");
}
@media screen and (max-width: 1250px) {
  .searchInput {
    margin-bottom: 20px;
  }
  .fa-search {
    bottom: 30px;
  }
  /* .s-header-left{
            width: 100%;
            margin: 0 auto;
        } 
        .s-header-right{
            width: 100%;
            text-align: center;
        } */
}
@media screen and (max-width: 1024px) {
  .search-header {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  /* .select-container{
            width: 20%;
            padding: 0 2%;
        } */
}
@media only screen and (max-width: 640px) {
  .produtos-wrapper .produto-item {
    min-width: 100%;
  }
  .seccao main .main-inner-wrapper .main-coluna-100p {
    max-width: calc(100vw - 100px) !important;
  }
  .produtos-wrapper .links {
    width: 100%;
  }
  .seccao.header-fixo main.listagem-produtos-com-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .produtos-wrapper.swiper-wrapper {
    width: 100%;
  }
  .produtos-swiper-button-prev {
    left: 5px;
  }
  .produtos-swiper-button-next {
    right: 5px;
  }
  .prod-video {
    max-height: 100%;
    height: auto;
    width: 100%;
    align-self: center;
  }
}
@media screen and (max-width: 512px) {
  /* .select-container{
            width: 45%;
            padding: 0 2%;
            margin-bottom: 10px;
        } */
  .s-input-container input {
    width: 200px;
  }
}
.holder {
  text-align: center;
}
.holder a {
  /* font-category: "Calibri Bold", sans-serif!important; */
  color: #cccecf !important;
  font-size: 30px !important;
}
.cart-form {
  width: 40%;
  margin-left: 3%;
  margin-top: 60px;
}
.cart-form p {
  margin: 0;
  color: #000;
  font-weight: bold;
  font-size: 25px;
}
.cart-form input {
  margin: 10px 0;
  font-size: 20px;
}
.cart-form input::placeholder {
  font-size: 20px;
}
#form-msg {
  margin-top: 10px;
  font-size: 14px;
}
</style>