<template>
  <section class="loader-container">
    <div class="bg"></div>
    <div
      class="loader"
      v-if="cart.template == 'consumiveis' || cart.template == 'personalizada'"
    >
      <h3 class="title-with-strap">
        Obrigado por confiar na <span style="color: #ff8e00">Glintt</span>
      </h3>

      <p>
        A sua encomenda
        <span style="color: #ff8e00">{{ cart.numero_encomenda }}</span> foi
        registada com sucesso.<br />
        A equipa Glintt já está a preparar o seu pedido! Irá ser contactado
        assim que a sua encomenda sair do nosso armazém.
      </p>

      <br /><br />
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tbody>
          <tr style="height: 100px" height="100">
            <td
              class="mcnImageCardBlockInner"
              valign="middle"
              style="
                background-color: #000000;
                color: #ffffff;
                width: 420px;
                padding-left: 20px;
                text-align: left;
                padding-top: 0px;
                padding-bottom: 0px;
              "
            >
              <span style="font-size: 30px; color: #fff"
                ><strong>Obrigado pela sua preferência</strong></span
              >
            </td>
            <td width="40">
              <img
                src="https://bydservices.pt/glintt-mailer/black-diagonal-right.png"
                style="
                  border: 0px initial;
                  width: 40px;
                  margin-left: auto;
                  margin-right: auto;
                "
                width="40"
              />
            </td>
            <td
              valign="middle"
              width="180"
              style="
                width: 180px;
                font-size: 16px;
                padding-left: 0px;
                padding-right: 10px;
              "
            >
              Sobre qualquer dúvida contacte o seu Gestor de Conta Glintt
            </td>
          </tr>
        </tbody>
      </table>

      <br /><br />
      <p>
        Foi enviado para seu email o resumo da sua encomenda.<br />
        Caso não tenha recebido o email por favor entre em contacto com o seu
        Gestor de Conta Glintt ou <b><router-link to="">AQUI</router-link></b>
      </p>

      <center>
        <br /><br />
        <div class="button-wrapper">
          <router-link to="/home">
            <a class="button padding-left-xxl padding-right-xxl diagonal">
              Voltar ao início
            </a>
          </router-link>
        </div>
      </center>
    </div>
    <div class="loader" v-else>
      <h3 class="title-with-strap">
        Obrigado por confiar na <span style="color: #ff8e00">Glintt</span>
      </h3>

      <p>
        A sua encomenda de consumíveis <b>{{ cart.numero_encomenda }}</b> e o
        seu pedido de encomenda <b>{{ cart.numero_pedido }}</b> foram registados
        com sucesso.<br />
        <br />
        A equipa Glintt já está a preparar o seu pedido de consumíveis!<br />
        Irá ser contactado assim que a sua encomenda sair do nosso armazém.
      </p>
      <p>
        O seu gestor de cliente Glintt irá entrar em contacto consigo para dar
        seguimento ao pedido de encomenda
        <span style="color: #ff8e00">{{ cart.numero_pedido }}</span
        >.
      </p>

      <br /><br />
      <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tbody>
          <tr style="height: 100px" height="100">
            <td
              class="mcnImageCardBlockInner"
              valign="middle"
              style="
                background-color: #000000;
                color: #ffffff;
                width: 420px;
                padding-left: 20px;
                text-align: left;
                padding-top: 0px;
                padding-bottom: 0px;
              "
            >
              <span style="font-size: 30px; color: #fff"
                ><strong>Obrigado pela sua preferência</strong></span
              >
            </td>
            <td width="40">
              <img
                src="https://bydservices.pt/glintt-mailer/black-diagonal-right.png"
                style="
                  border: 0px initial;
                  width: 40px;
                  margin-left: auto;
                  margin-right: auto;
                "
                width="40"
              />
            </td>
            <td
              valign="middle"
              width="180"
              style="
                width: 180px;
                font-size: 16px;
                padding-left: 0px;
                padding-right: 10px;
              "
            >
              Sobre qualquer dúvida contacte o seu Gestor de Conta Glintt
            </td>
          </tr>
        </tbody>
      </table>

      <br /><br />
      <p>
        Foi enviado para seu email o resumo da sua encomenda.<br />
        Caso não tenha recebido o email por favor entre em contacto com o seu
        Gestor de Conta Glintt ou <b><router-link to="">AQUI</router-link></b>
      </p>

      <center>
        <br /><br />
        <div class="button-wrapper">
          <router-link to="/home">
            <a class="button padding-left-xxl padding-right-xxl diagonal">
              Voltar ao início
            </a>
          </router-link>
        </div>
      </center>
    </div>
  </section>
</template>

<script>
export default {
  name: "cartThanks",
  props: ["cart"],
};
</script>

<style scoped>
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;
}

.bg {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.9;
}

.loader {
  position: absolute;
  top: calc(20%);
  left: calc(25vw - 40px);
  margin: 0 0 2em;
  height: auto;
  width: 50vw;
  /* text-align: center; */
  padding: 40px;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
  background: #fff;
}
</style>

