<template>
  <a class="links" :key="'/produto/' + product_data.id">
    <article>
      <div class="color-bg" :style="{ backgroundColor: '#cccecf' }"></div>
      <div class="text-container">
        <p style="color: #000; font-weight: bold; font-size: 20px">
          {{ product_data.feed_description }}
        </p>
        <h2
          class="title"
          :id="'item_' + product_data.g_product_id"
          :style="{ color: '#ea8822', marginTop: '5px' }"
        >
          <router-link
            class="links"
            :to="{
              path:
                '/produto/' +
                product_data.id +
                '-' +
                product_data.name.replaceAll(' ', '-').toLowerCase(),
            }"
          >
            {{ product_data.name }}
          </router-link>
        </h2>
        <p style="width: 80%; color: #444 !important">
          {{ product_data.action_sentence }}
        </p>
      </div>

      <div class="number-items">
        <p class="one-line">
          <a @click="decrease(i)" class="quantity">-</a>
          <span class="quantity-value">{{ product_data.quantity }}</span>
          <a @click="increase(i)" class="quantity">+</a>
        </p>
        <span class="unidade">un</span>
      </div>

      <figure>
        <img :src="'assets/fotos_catalogo/products/' + product_data.image_1" />
      </figure>

      <div class="line-total">
        <p style="display: inline-block; vertical-align: middle">
          <span class="price-subtitle" v-if="product_data.price == 0.01"></span>
          <span
            class="price"
            :style="{ color: '#ea8822' }"
            v-if="product_data.price == 0.01"
            >Oferta</span
          >

          <span
            class="price-subtitle"
            :style="{ color: '#ea8822' }"
            v-if="product_data.price != 0.0 && product_data.price != 0.01"
            >desde</span
          >
          <span
            class="price"
            :style="{ color: '#ea8822' }"
            v-if="product_data.price != 0.0 && product_data.price != 0.01"
            >{{
              parseInt(product_data.price).toFixed(2).replace(".", ",")
            }}€</span
          >

          <span class="price-subtitle" v-if="product_data.price == 0.0"></span>
          <span
            class="price"
            :style="{ color: '#ea8822' }"
            v-if="product_data.price == 0.0"
            >Sob consulta</span
          >
        </p>
      </div>

      <div
        class="btn-container"
        :style="{ border: '2px solid ' + '#ea8822' }"
        @click="removeItem(i)"
      >
        <a class="links" :key="'/produto/' + product_data.id">
          <!-- <div class="icon-container" :style="{backgroundColor: '#ea8822'}">
                        <img src="@/assets/imagens/icone-carrinho.svg">
                    </div> -->
          <i
            style="
              align-self: center;
              transform: skew(15deg);
              margin-left: 7px;
              z-index: 2;
            "
            class="fas fa-trash"
          ></i>
        </a>
      </div>
    </article>
  </a>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>

<script>
  export default {
    name: "CartCard",
    props: ["product_data", "i"],
    data() {
      return {
        // product_data: {},
        // area_data: {}
        n: 0,
      };
    },
    mounted() {
      // this.collect_product_data()
      var styleNode = document.createElement("style");
      styleNode.type = "text/css";
      var styleText = document.createTextNode(
        "#item_" +
          this.product_data.g_product_id +
          ":after {background-color: #ea8822 !important;}:root {--cor-principal: #2fb68e !important;--cor-1: #2fb68e !important;}"
      );
      styleNode.appendChild(styleText);

      document.getElementsByTagName("head")[0].appendChild(styleNode);
    },
    computed: {
      materiaisCarrinho() {
        this.n;
        let temp = JSON.parse(window.sessionStorage.getItem("cart")) || [];

        return temp;
      },
      m() {
        this.backdoor;
        return this.n;
      },
      cartItems() {
        this.n;
        let len = this.materiaisCarrinho.length || 0;

        return len;
      },
    },
    methods: {
      increase: async function (i) {
        this.n += 1;
        this.materiaisCarrinho[i].quantity++;
        let cart = this.materiaisCarrinho;

        window.sessionStorage.setItem("cart", JSON.stringify(cart));

        this.$emit("update-cart");
      },
      decrease: async function (i) {
        this.n += 1;
        if (parseInt(this.materiaisCarrinho[i].quantity) > 1) {
          this.materiaisCarrinho[i].quantity =
            parseInt(this.materiaisCarrinho[i].quantity) - 1;
        } else {
          this.materiaisCarrinho[i].quantity = 1;
        }
        let cart = this.materiaisCarrinho;

        window.sessionStorage.setItem("cart", JSON.stringify(cart));

        this.$emit("update-cart");
      },
      async removeItem(i) {
        if (
          confirm(
            "Tem a certeza que quer remover o produto '" +
              this.product_data.name +
              "' do seu carrinho?"
          )
        ) {
          this.n += 1;
          this.materiaisCarrinho.splice(i, 1);

          let cart = this.materiaisCarrinho;

          window.sessionStorage.setItem("cart", JSON.stringify(cart));

          this.$emit("update-cart");
        }
      },
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .number-items {
    display: inline-flex;
    width: 15%;
    justify-content: center;
    align-items: center;
  }

  .one-line {
    display: inline-flex;
    background: #eee;
    transform: skew(-15deg);
    height: 50px;
  }

  .quantity {
    display: inline-flex;
    font-size: 25px;
    padding: 20px;
    transform: skew(15deg);
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .quantity-value {
    display: inline-flex;
    font-size: 25px;
    transform: skew(15deg);
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .unidade {
    color: rgb(194, 196, 197);
    font-size: 25px;
    padding: 10px;
  }

  .line-total {
    display: inline-flex;
    width: 18%;
    min-height: 100%;
    margin-left: 6%;
    padding-right: 50px;
    padding-left: 50px;
    background-color: #eee;
    position: absolute;
    align-items: center;
    justify-content: left;
  }

  .line-total::before {
    content: "";
    position: absolute;
    top: 0;
    left: -30px;
    right: 0;
    bottom: 0;
    width: 20%;
    background-color: #eee;
    transform: skew(-15deg);
    -webkit-transform: skew(-15deg);
    display: inline-block;
    z-index: -1;
  }
  .hover:hover {
    cursor: pointer;
  }
  article {
    width: 100%;
    padding: 0px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    min-height: 175px;
    margin-bottom: 20px;
    /* background: rgb(255,255,255);
        background: linear-gradient(110deg, rgba(255,255,255,1) 75%, rgba(246,246,246,1) 75%);
        background-position: right; */
  }
  .color-bg {
    background-color: #214496;
    transform: skew(-10deg);
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  figure {
    position: relative;
    z-index: 3;
    overflow: hidden;
    width: 20%;
    height: 155px;
    display: inline-block;
    vertical-align: middle;
    margin: 20px;
    padding: 0px;
  }
  figure img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .text-container {
    /* position: relative; */
    z-index: 3;
    display: inline-block;
    width: 35%;
    margin-left: 3%;
    vertical-align: middle;
  }

  .title {
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 30px;
    margin-bottom: 12px;
  }

  /* .title:after{
        content:"";
        background-color: #214496;
        height: 6px;
        width: 40px;
        margin-top: 10px;
        display: block;
    } */

  p {
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
  }
  li {
    display: inline-block;
    margin-right: 20px;
  }

  li a {
    border: 2px solid #b7b9bb;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 8px;
  }

  li i {
    display: inline-block;
    line-height: 15px;
    font-size: 11px;
    color: #b7b9bb;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  li p {
    display: inline-block;
    color: #b7b9bb;
    line-height: 15px;
    vertical-align: top;
  }

  .price-subtitle {
    color: #214496;
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 16px;
    display: block;
    font-weight: 600;
    line-height: 18px;
    margin-top: 15px;
  }

  .price {
    color: #214496;
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 25px;
    display: block;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .btn-container {
    position: absolute;
    /* right: -8vw; */
    padding-right: 15px;
    right: -15px;
    top: calc(50% - 25px);
    border: 2px solid #214496;
    background-color: #ea8822;
    color: #fff;
    transform: skew(-15deg);
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    /* z-index: 99; */
  }
  .btn-container:hover {
    cursor: pointer;
    background-color: #b35c00;
    border: 2px solid #b35c00;
    color: #eee;
  }

  .icon-container {
    position: absolute;
    right: -1px;
    top: -1px;
    background-color: #214496;
    width: 41px;
    height: 47px;
    border: none;
  }

  .icon-container img {
    width: 34px;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%) skew(10deg);
    /* font-size: 21px;
        margin-top: 9px;
        margin-left: 8px;
        color: white; */
  }

  .btn-container button {
    font-family: inherit;
    font-weight: 500;
    transform: skew(10deg);
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: #214496;
    text-align: left;
    margin-left: 15px;
    font-size: 16px;
  }

  @media screen and (max-width: 1350px) {
    article {
      padding: 20px 10px;
    }
    .text-container {
      margin-left: 30px;
    }
  }
  @media screen and (max-width: 512px) {
    figure {
      width: 90%;
      height: 200px;
    }
    .color-bg {
      height: 270px;
    }
    .text-container {
      width: 90%;
    }
    .btn-container {
      position: relative;
      left: 0;
      margin-top: 20px;
    }

    .title {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
</style>
